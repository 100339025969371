import { Vue, Options } from 'vue-class-component';
import {
    SET_APP_LOADING,
    SET_PROFILE,
    SET_TOKEN,
} from '@/core/store/action-types';
import { PAGE_DASHBOARD } from '@/core/router/pages';
import fruttaMeClient from '@/core/api/fruttame-service';
import { LoginDTO } from '@/../client-generator/generated-clients/client';
import { modalController } from '@ionic/vue';

import LoginPanel from './LoginPanel/LoginPanel.vue';

@Options({
    name: 'LoginPage',
})
export default class LoginPage extends Vue {
    username = '';
    password = '';
    rememberMe = false;

    panelError: HTMLIonModalElement | null = null;

    get version() {
        return this.$config.FRONTEND_VERSION;
    }

    async doLogin() {
        this.$store.dispatch(SET_APP_LOADING, true);

        try {
            const token = await fruttaMeClient.token(
                new LoginDTO({
                    username: this.username,
                    password: this.password,
                })
            );

            await this.$store.dispatch(SET_TOKEN, {
                token,
                rememberMe: this.rememberMe,
            });
            const profile = await fruttaMeClient.me();
            if (profile.roles && profile.roles.includes('Supplier')) {
                await this.$store.dispatch(SET_PROFILE, profile);
                this.$router.push({
                    name: PAGE_DASHBOARD,
                });
            } else {
                await this.$store.dispatch(SET_TOKEN, null);
                this.openErrorPanel();
            }
        } catch (error) {
            switch (error.status) {
                case 401:
                    this.$messageService.error(
                        this.$t('login.usernameOrPasswordWrong')
                    );
                    break;
                default:
                    this.$messageService.error(this.$t('general.apiError'));
                    break;
            }
        } finally {
            this.$store.dispatch(SET_APP_LOADING, false);
        }
    }

    async openErrorPanel() {
        this.panelError = await modalController.create({
            component: LoginPanel,
            backdropDismiss: false,
            cssClass: 'panel',
            componentProps: {
                closePanel: this.closePanel,
            },
        });

        this.panelError.present();
    }

    closePanel() {
        if (this.panelError) {
            this.panelError.dismiss();
            this.username = '';
            this.password = '';
        }
    }
}
