<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="page-login">
                <img
                    src="~@/assets/images/logo.png"
                    alt="FuttaMe Logo"
                    class="page-login__logo"
                />

                <form
                    action="#"
                    class="page-login__form w-100"
                    @submit.prevent="doLogin"
                >
                    <div class="input-group">
                        <span
                            class="
                                input-group-text
                                text-secondary
                                bg-transparent
                            "
                        >
                            <font-awesome-icon icon="envelope" />
                        </span>
                        <input
                            type="text"
                            :placeholder="$t('login.username')"
                            class="form-control border-start-0 border-end-0"
                            required="true"
                            v-model="username"
                        />
                        <button
                            type="button"
                            @click="openErrorPanel"
                            class="
                                input-group-text
                                text-secondary
                                bg-transparent
                            "
                        >
                            <font-awesome-icon icon="question-circle" />
                        </button>
                    </div>
                    <div class="input-group">
                        <span
                            class="
                                input-group-text
                                text-secondary
                                bg-transparent
                            "
                        >
                            <font-awesome-icon icon="lock" />
                        </span>
                        <input
                            type="password"
                            :placeholder="$t('login.password')"
                            class="form-control border-start-0"
                            required="true"
                            v-model="password"
                        />
                    </div>

                    <div
                        class="
                            d-flex
                            align-items-center
                            justify-content-between
                            my-3
                            fs-12
                        "
                    >
                        <div class="form-check d-flex align-items-center">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="rememberMe"
                                id="flexCheckDefault"
                            />
                            <label
                                class="form-check-label text-secondary"
                                for="flexCheckDefault"
                            >
                                {{ $t("login.rememberMe") }}
                            </label>
                        </div>

                        <a href="#" class="text-decoration-none">{{
                            $t("login.forgottenPassword")
                        }}</a>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <button
                                type="submit"
                                class="btn btn-success w-100 mt-3"
                            >
                                {{ $t("general.enter") }}
                            </button>
                        </div>
                    </div>
                </form>

                <p class="mt-auto mb-0 fs-12">
                    &copy; <strong>FruttaMe </strong> - v.{{ version }}
                </p>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Login.ts"></script>

<styles lang="scss" src="./Login.scss"></styles>
