import { Vue, Options } from 'vue-class-component';
import { close as iconClose } from 'ionicons/icons';

@Options({
    name: 'LoginPanel',
    props: {
        closePanel: {
            type: Function,
        },
    },
})
export default class LoginPanel extends Vue {
    iconClose = iconClose;
}
