<template>
    <ion-header>
        <ion-toolbar>
            <span class="panel__title"> {{ $t("general.title") }}</span>
            <ion-buttons slot="end">
                <ion-button @click="closePanel">
                    <ion-icon
                        fill="clear"
                        color="dark"
                        :icon="iconClose"
                    ></ion-icon>
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" :scrollY="false">
        <p v-html="$t('login.onlySupplierLoginMessage')"></p>
    </ion-content>
    <ion-footer>
        <ion-toolbar>
            <ion-button fill="outline" @click="closePanel">
                {{ $t("general.close") }}
            </ion-button>
        </ion-toolbar>
    </ion-footer>
</template>

<script lang="ts" src="./LoginPanel.ts"></script>
